<template>
  <!-- 仪表盘 -->
  <div :id="id" class="chartsBox"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  props: {
    id: {
      type: String,
      default: "chartLine",
    },
    option: {
      type: Object,
      default: () => { },
      // {
      //  lineStyleColor: '底色',
      //  color: '显示颜色',
      //  width:"底色宽度",
      //  setSeries:{},
      //  data: [{
      //       value: 20,
      //       name: 'Perfect',
      //       title: {
      //         offsetCenter: ['0%', '0%']
      //       },
      //       detail: {
      //         valueAnimation: true,
      //         offsetCenter: ['0%', '-10%']
      //       }
      //     }],
      //};
    },
  },
  data() {
    return {
      chart: null,
      chartDom: null,
      defaultData: {
        series: [{
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,//圆角或 平角false
            clip: false,
          },
          axisLine: {
            lineStyle: {
              width: 40,
              color: [
                [1, '#fd666d']
              ]
            }
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            distance: 50
          },
          data: [{
            value: 20,
            name: 'Perfect',
            title: {
              offsetCenter: ['0%', '0%']
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ['0%', '-10%']
            }
          }],
          title: {
            color: '#506179',
            fontSize: 14
          },
          detail: {
            width: 50,
            height: 18,
            fontSize: 14,
            color: '#24334A',
            borderColor: 'auto',
            formatter: '{value}%'
          }
        }
        ]

      },
    };
  },
  watch: {
    data() {
      this.initOption();
    },
  },
  methods: {

    handle() {
      this.defaultData.color = this.option.color;
      this.defaultData.series[0].data = this.option.data;
      this.defaultData.series[0].axisLine.lineStyle.color[0] = [1, this.option.lineStyleColor];
      this.defaultData.series[0].axisLine.lineStyle.width = this.option.width;
      Object.assign(this.defaultData.series[0], this.option.setSeries);
      return this.defaultData;
    },
    initOption() {
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      newPromise.then(() => {
        this.chartDom = document.getElementById(this.id);
        this.chart = echarts.init(this.chartDom);
        if (this.option) {
          this.chart.setOption(this.handle());
        }
      });
    },
  },
  mounted: function () {
    this.initOption();
  },
};
</script>
<style scoped>
.chartsBox {
  width: 100%;
  height: 100%;
}
</style>

